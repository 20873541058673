import { FormattedMessage } from "react-intl";
import PermissionIcon from "assets/img/permissions.png";

const StreamingDisconnection = () => {
  return (
    <div className="tvp-w-full tvp-h-auto tvp-bg-white tvp-my-4 tvp-flex-grow tvp-flex tvp-flex-col tvp-justify-center">
      <div className="tvp-w-full tvp-flex tvp-flex-row tvp-justify-around">
        <img className="tvp-permissionIcon tvp-mt-5" src={PermissionIcon} alt="permissionIcon" />
      </div>
      <span className="tvp-mt-4">
        <h6 className="tvp-my-3 tvp-font-bold tvp-text-center tvp-clr-neutral">
          <FormattedMessage id="component.streamingDisconnection.content-1" />
        </h6>
      </span>
      <div className="tvp-text-center tvp-w-5/6 tvp-mx-auto">
        <p>
          <FormattedMessage id="component.streamingDisconnection.content-2" />
        </p>
      </div>
    </div>
  );
};

export default StreamingDisconnection;
