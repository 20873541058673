import LanguageSwitch from "components/LanguageSwitch";
import proviewGrey from "assets/img/Proview_logo_grey.png";
interface Props {
  title: string | React.ReactNode;
  titleClass: string;
  tabIndex?: number;
}
const Header: React.FC<Props> = ({ title, titleClass, tabIndex }) => {
  return (
    <div className="tvp-flex tvp-flex-row tvp-h-12 tvp-items-center">
      <img className="tvp-flex tvp-flex-row tvp-h-4/5" src={proviewGrey} alt="Proview logo" />
      {title && (
        <h2 className={titleClass} role="heading">
          {title}
        </h2>
      )}
      <LanguageSwitch tabIndex={tabIndex}/>
    </div>
  );
};
export default Header;
