/* eslint-disable */
import { FeatureFlags, SessionScope } from "globals/enums";
import { appId, proctoringApiBaseUrl } from "config/constant";
import { FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PreCheckItem } from "./precheck";

export interface EnforceTSB {
  current_browser: string;
  redirect_url: string;
  minimize_option: string;
  blacklisted_softwares_windows: string;
  blacklisted_softwares_mac: string;
  whitelisted_softwares_windows: string;
  whitelisted_softwares_mac: string;
  isScreenProtectionEnabled: string;
}

export interface VerificationTokenApiPayload {
  proctor_token: string;
  attendee_external_id: string;
  session: {
    external_proctor_event_id?: string;
    external_id: string;
    scope: SessionScope | string;
    title?: string;
  };
  configuration: {
    enforce_tsb?: EnforceTSB;
  };
}

export interface VerificationTokenApiResponse {
  verification_code: string;
  systemTimeDiff: number;
  configuration: {
    alert_configs: AlertLogConfig[];
    audio_call: boolean;
    audio_check: boolean;
    chat_support_event: boolean;
    chat_support_precheck: boolean;
    end_buffer_time: number;
    environment_check: boolean;
    eye_gaze: boolean;
    face_capture: boolean;
    high_touch_proctoring: boolean;
    id_verification_check: boolean;
    midway_face_verification: boolean;
    mixpanel_analytics: boolean;
    object_detection: boolean;
    disable_noise_detection: boolean;
    organization_id: number;
    pre_flight_approval: boolean;
    screen_grab: boolean;
    start_buffer_time: number;
    terms_and_conditions: boolean;
    terms_and_condition_url: string;
    privacy_url: string;
    support_url: string;
    twilio_streaming: boolean;
    type: string;
    valid_from: string;
    valid_till: string;
    video_recording: boolean;
    firebase_db: boolean;
    proctor_initiated_audio_call: boolean;
    screen_recording: boolean;
    secondary_camera: boolean;
    brand_color: string;
    brand_logo: string;
    streaming_provider: string;
    feedback: boolean;
  };
}

export interface SessionTokenApiPayload {
  verification_token: string;
}

export interface Session {
  session_id: number;
  session_token: string;
  session_type: SessionScope;
  session_uuid: string;
  old_session_uuid?: string;
  organization_id: number;
  instruction: string;
  secondary_camera_token: string;
  session_token_id: number;
  attendee_id: number;
}

export interface SessionTokenApiResponse {
  session_token: string;
  session_id: number;
  session_uuid: string;
  attendee_id: number;
  session_type: SessionScope;
  chat: Chat;
  configuration: Configurations;
  instruction: string;
  secondary_camera_token: string;
  storage: SessionStorage;
  identification: Identification;
  organization_id: number;
  session_token_id: number;
  brand_color: string;
}

export interface SessionStorage {
  service: string;
  sas_token: string;
  sas_expiries_in: string;
  sas_url: string;
  container_name: string;
  file_destination: {
    screen_grab: string;
    camera_grab: string;
    test_env: string;
    video: string;
  };
}

export interface Identification {
  photo_id: boolean;
  photo: boolean;
}

export interface Chat {
  token: string;
  user_id: string;
}

export interface AlertLogConfig {
  alert_type_id: number;
  id: number;
  name: string;
  prompt_each_time: boolean;
  prompt_min_occurrence: number | null;
  threshold: number | null;
  severity: string;
}

export interface FireBaseState {
  pre_check: PreCheckItem[];
  status: string;
  termination_reason: string;
  audio_call_initiated: boolean;
  proctor_group_room_identity: string;
  secondary_device_status: string;
}

export interface Configurations {
  [FeatureFlags.ProctorInitiatedAudioCall]: boolean;
  [FeatureFlags.Firebase]: boolean;
  alert_configs?: AlertLogConfig[];
  full_screen_mode?: boolean;
  [FeatureFlags.AudioCall]: boolean;
  [FeatureFlags.AudioCheck]: boolean;
  [FeatureFlags.ChatSupportEvent]: boolean;
  [FeatureFlags.ChatSupportPrecheck]: boolean;
  [FeatureFlags.EndBufferTime]: number;
  [FeatureFlags.EnvironmentCheck]: boolean;
  [FeatureFlags.EyeGaze]: boolean;
  [FeatureFlags.FaceCapture]: boolean;
  [FeatureFlags.HighTouchProctoring]: boolean;
  [FeatureFlags.IdVerificationCheck]: boolean;
  [FeatureFlags.MidwayFaceVerification]: boolean;
  [FeatureFlags.MixpanelAnalytics]: boolean;
  [FeatureFlags.ObjectDetection]: boolean;
  [FeatureFlags.DisableNoiseDetection]: boolean;
  [FeatureFlags.OrganizationId]: number;
  [FeatureFlags.PreFlightApproval]: boolean;
  [FeatureFlags.ScreenGrab]: boolean;
  [FeatureFlags.StartBufferTime]: number;
  [FeatureFlags.TermsAndConditions]: boolean;
  [FeatureFlags.TermsAndConditionsURL]: string;
  [FeatureFlags.PrivacyURL]: string;
  [FeatureFlags.SupportURL]: string;
  [FeatureFlags.TwilioStreaming]: boolean;
  [FeatureFlags.Type]: string;
  [FeatureFlags.ValidFrom]: string;
  [FeatureFlags.ValidTill]: string;
  [FeatureFlags.VideoRecording]: boolean;
  [FeatureFlags.ScreenRecording]: boolean;
  [FeatureFlags.SecondaryCamera]: boolean;
  [FeatureFlags.BrandColor]: string;
  [FeatureFlags.BrandLogo]: string;
  [FeatureFlags.StreamingProvider]: string;
  [FeatureFlags.Feedback]: boolean;
  is_mobile_supported?: boolean;
}

interface SASTokenRefreshPayload {
  session_token: string;
  sas_token: string;
  session_uuid: string;
}

export const sessionTokenApi = createApi({
  reducerPath: "sessionToken",
  baseQuery: fetchBaseQuery({
    baseUrl: proctoringApiBaseUrl,
    prepareHeaders: (headers: Headers) => {
      headers.set("app-id", appId as string);
    }
  }),
  keepUnusedDataFor: 86400000000,
  endpoints: (builder) => ({
    generateVerificationCode: builder.query({
      queryFn: async (
        payload: VerificationTokenApiPayload,
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const result = await fetchWithBQ({
          url: "v1/token/verification-code",
          method: "POST",
          body: payload
        });

        const serverTime = result.meta.response.headers.get("date");
        const systemTimeDiff = serverTime
          ? new Date(serverTime).getTime() - new Date().getTime()
          : 0;

        return result.data
          ? { data: { ...(result.data as object), systemTimeDiff } as VerificationTokenApiResponse }
          : { error: result.error as FetchBaseQueryError };
      }
    }),
    generateSessionToken: builder.query({
      queryFn: async (payload: SessionTokenApiPayload, _queryApi, _extraOptions, fetchWithBQ) => {
        const result = await fetchWithBQ({
          url: "v1/session/token",
          method: "POST",
          body: payload
        });
        return result.data
          ? { data: result.data as SessionTokenApiResponse }
          : { error: result.error.data as FetchBaseQueryError };
      },
      keepUnusedDataFor: 86400000000
    }),
    refreshSASToken: builder.mutation({
      query: (payload: SASTokenRefreshPayload) => {
        return {
          url: "v1/sas-token/refresh",
          method: "POST",
          body: payload
        };
      }
    })
  })
});

export const {
  useGenerateVerificationCodeQuery,
  useGenerateSessionTokenQuery,
  useRefreshSASTokenMutation
} = sessionTokenApi;
