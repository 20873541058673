import { getDatabase, ref, update } from "firebase/database";

import dayjs from "dayjs";

export const markCandidateMessagesAsReadInFirebase = (session_id: number, messages) => {
  if (!messages) {
    return;
  }
  const database = getDatabase();
  Object.keys(messages).forEach((messageKey: any) => {
    const message = messages[messageKey];
    if (message.role !== "candidate" && !message.read_at) {
      const messageRef = ref(database, `session_v2/${session_id}/messages/${messageKey}`);
      update(messageRef, {
        received_at: new Date().toISOString(),
        read_at: new Date().toISOString()
      });
    }
  });
};

export const formatDate = (date: string) => {
  return date ? dayjs(date).format("hh:mm A") : "";
};
