import React from "react";
import PermissionIcon from "../../assets/img/permissions.png";

const ErrorInformationPage = ({ heading, subheading }) => {
  return (
    <div className="tvp-w-full tvp-h-auto tvp-bg-white tvp-my-4 tvp-flex-grow tvp-flex tvp-flex-col tvp-justify-center">
      <div className="tvp-w-full tvp-flex tvp-flex-row tvp-justify-around tvp-mb-4">
        <img className="tvp-permissionIcon tvp-mt-5" src={PermissionIcon} alt="permissionIcon" />
      </div>
      {heading && <h3 className="tvp-mb-3 tvp-font-bold tvp-text-xl tvp-text-center tvp-clr-neutral">{heading}</h3>}
      <div className="tvp-text-center tvp-w-10/12 tvp-ml-auto tvp-mr-auto">
        <p>{subheading}</p>
      </div>
    </div>
  );
};

export default ErrorInformationPage;
