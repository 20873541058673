import { TwilioAudioCallEvent } from "globals/enums";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { AlertLogConfig } from "services/session-token";

const lastNoiseAlertHashMap = {
  timestamp: null,
  type: AlertName.NoiseDetected
};

export const noiseAlertHandler = (
  processor,
  audioCounter,
  twilioCallStatus: TwilioAudioCallEvent,
  alertConfig: AlertLogConfig[],
  dispatch
) => {
  if (processor.current) {
    if (
      audioCounter.current > 30 &&
      twilioCallStatus !== TwilioAudioCallEvent.Incoming &&
      twilioCallStatus !== TwilioAudioCallEvent.Requested &&
      (lastNoiseAlertHashMap.timestamp
        ? Date.now() - lastNoiseAlertHashMap.timestamp > 10000
        : !lastNoiseAlertHashMap.timestamp)
    ) {
      alertHelper(alertConfig).raiseAlert(lastNoiseAlertHashMap.type, dispatch);
      lastNoiseAlertHashMap.timestamp = Date.now();
      audioCounter.current = 0;
    }
  }
};
