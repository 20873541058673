import mixpanel, { Dict } from "mixpanel-browser";
import { StorageKeyNames } from "globals/enums";
import { mixpanelToken } from "./constant";
let isMixpanelInitialized = false;

interface MixpanelParams {
  profile_id: string;
  session_id?: number | undefined;
  session_uuid: string;
  session_type: string;
  organization_id: number;
}

export const isMixPanelEnable = (): boolean => {
  return false; // NOTE : Disabled the Mixpanel
};

export const frameMixPanelData = (): Dict | undefined => {
  const mixPanelData = sessionStorage.getItem(StorageKeyNames.MixPanelData);
  if (!mixPanelData) {
    return undefined;
  }

  const data = JSON.parse(mixPanelData);

  const mixPanelObject = {
    session_uuid: data.UID as string,
    session_type: data.type as string,
    organization_id: data.orgId as number,
    distinct_id: data.profileId as string,
    session_id: data.sessionId as number
  };
  return mixPanelObject;
};

export const setDataForMixpanel = (mixpanelParams: MixpanelParams) => {
  const data = {
    orgId: mixpanelParams?.organization_id,
    UID: mixpanelParams?.session_uuid,
    type: mixpanelParams?.session_type,
    profileId: mixpanelParams?.profile_id,
    sessionId: mixpanelParams?.session_id
  };
  sessionStorage.setItem(StorageKeyNames.MixPanelData, JSON.stringify(data));
  return data;
};

const mixPanelActions = {
  init: (sessionTokenResponse: any, profileId: string) => {
    if (isMixPanelEnable()) {
      const params = {
        ...sessionTokenResponse,
        profile_id: profileId
      };
      setDataForMixpanel(params);
      mixpanel.init(mixpanelToken as string, { debug: true });
      isMixpanelInitialized = true;
    }
  },
  track: (alert: string) => {
    if (isMixPanelEnable() && isMixpanelInitialized) {
      const mixpanelData = frameMixPanelData();
      mixpanel.track(alert, mixpanelData);
    }
  },
  identify: (userId: string) => {
    if (isMixPanelEnable()) {
      mixpanel.identify(userId);
    }
  },
  setProfile: () => {
    const mixpanelData = frameMixPanelData();
    if (isMixPanelEnable()) {
      const mixpanalDataWithName = {
        ...mixpanelData,
        $name: mixpanelData?.session_id
      };
      mixpanel.people?.set(mixpanalDataWithName); // Set the user profile in Mixpanel
    }
  }
};

export default mixPanelActions;
