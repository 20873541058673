import React from "react";

type MessageProps = {
  className: string;
  messages: string | React.ReactNode;
};

const Message = (props: MessageProps) => {
  return (
    <div className={props.className} aria-live="polite">
      { props.messages && <p>{props.messages}</p>}
    </div>
  );
};

export default Message;
