import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from "@reduxjs/toolkit/query/react";
import type { RootState } from "store/store";
import { appId } from "config/constant";

export interface CreateBlobPayload {
  url: string;
  data: Blob;
  alert_type_id: number;
  offline_batch_id?: string;
  offline_sequence_id?: number;
  offline_at?: string;
}

const blobBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  WebAPI,
  extraOptions
) => {
  const storage = (WebAPI.getState() as RootState).app.storage;
  const rawBaseQuery = fetchBaseQuery({
    // eslint-disable-next-line
    baseUrl: storage?.sas_url! + storage?.container_name,
    prepareHeaders(headers, api) {
      const session = (api.getState() as RootState).app.session;
      headers.set("x-ms-blob-type", "BlockBlob");
      headers.set("x-ms-meta-sessionToken", session.session_token!);
      // eslint-disable-next-line
      headers.set("x-ms-meta-sessionUuid", storage?.container_name!);
      headers.set("Content-Type", "multipart/form-data");
      headers.set("x-ms-meta-uploadTime", Date.now().toString());
      headers.set("x-ms-meta-createdAt", Date.now().toString());
      headers.set("x-ms-meta-appId", appId);
    }
  });
  return rawBaseQuery(args, WebAPI, extraOptions);
};

export const blobApi = createApi({
  reducerPath: "blob",
  baseQuery: blobBaseQuery,
  endpoints: (builder) => ({
    createBlob: builder.mutation({
      query: (payload: CreateBlobPayload) => {
        if (payload.offline_batch_id) {
          return {
            url: payload.url,
            method: "PUT",
            body: payload.data,
            headers: {
              "x-ms-meta-alertTypeId": payload.alert_type_id.toString(),
              "x-ms-meta-offlineBatchId": payload.offline_batch_id,
              "x-ms-meta-offlineSequenceId": payload.offline_sequence_id.toString(),
              "x-ms-meta-offlineAt": payload.offline_at
            }
          };
        } else {
          return {
            url: payload.url,
            method: "PUT",
            body: payload.data,
            headers: {
              "x-ms-meta-alertTypeId": payload.alert_type_id.toString()
            }
          };
        }
      },
      transformErrorResponse(error: any) {
        return error?.data?.message;
      }
    })
  })
});

export const { useCreateBlobMutation } = blobApi;
