import { useContext, useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch, RootState } from "store/store";
import { MediaContext } from "context/MediaContext";
import { AlertLogConfig } from "services/session-token";
import { SessionScope } from "globals/enums";
import { noiseAlertHandler } from "helpers/noise-alert-helper";

const useNoiseDetection = () => {
  const { audioStream } = useContext(MediaContext);
  const dispatch = useAppDispatch();
  const session = useAppSelector((state: RootState) => state.app.session);
  const isCandidateAuth = session.session_type === SessionScope.CandidateAuth;
  const twilioCallStatus = useAppSelector((state) => state.app.twilioAudioCallStatus);
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const precheckSuccess = useAppSelector((state) => state.app.precheckSuccess);
  const processor = useRef(null);
  const audioCounter = useRef(0);
  const audioContext = useRef(null);
  const noiseAlertTimer = useRef<NodeJS.Timer | null>(null);

  const startNoiseDetection = () => {
    audioContext.current = new AudioContext();
    if (audioStream) {
      const streamNode = audioContext.current.createMediaStreamSource(audioStream);
      const meter = createAudioMeter(audioContext.current);
      streamNode.connect(meter);
    }
  };

  const calculateRMS = (buffer) => {
    let sum = 0;
    for (let i = 0; i < buffer.length; i++) {
      if (Math.abs(buffer[i]) >= processor.current.clipLevel) {
        processor.current.clipping = true;
        processor.current.lastClip = window.performance.now();
      }
      sum += buffer[i] * buffer[i];
    }
    const meanSquare = sum / buffer.length;
    const rms = Math.sqrt(meanSquare);
    return rms;
  };

  const createAudioMeter = (audioContext: any) => {
    processor.current = audioContext.createScriptProcessor(512);
    processor.current.onaudioprocess = (audioProcessingEvent: any) => {
      const buf = audioProcessingEvent.inputBuffer.getChannelData(0);
      const rms = calculateRMS(buf);
      processor.current.volume = Math.max(
        rms,
        processor.current.volume * processor.current.averaging
      );
      if (processor.current.volume > 0.02) {
        const audioLevel = processor.current.volume * 400;
        if (audioLevel > 40) {
          audioCounter.current++;
        }
      }
    };
    processor.current.clipping = false;
    processor.current.lastClip = 0;
    processor.current.volume = 0;
    processor.current.clipLevel = 0.98;
    processor.current.averaging = 0.95;
    processor.current.clipLag = 750;
    processor.current.connect(audioContext.destination);
    processor.current.checkClipping = () => {
      if (!processor.current.clipping) {
        return false;
      }
      if (processor.current.lastClip + processor.current.clipLag < window.performance.now()) {
        processor.current.clipping = false;
      }
      return processor.current.clipping;
    };

    processor.current.shutdown = () => {
      processor.current.disconnect();
      processor.current.onaudioprocess = null;
    };
    return processor.current;
  };

  useEffect(() => {
    noiseAlertTimer.current = setInterval(() => {
      noiseAlertHandler(processor, audioCounter, twilioCallStatus, alertConfig, dispatch);
    }, 50);
    return () => clearInterval(noiseAlertTimer.current);
  }, []);

  useEffect(() => {
    const startDetection = audioStream && !isCandidateAuth && !processor.current && precheckSuccess;
    const stopDetection = !audioStream && processor.current;

    if (startDetection) {
      startNoiseDetection();
    }

    if (stopDetection) {
      processor.current.shutdown();
      clearInterval(noiseAlertTimer.current);
    }

    return () => {
      processor.current?.shutdown();
    };
  }, [audioStream, isCandidateAuth, precheckSuccess]);

  return;
};

export default useNoiseDetection;
