import { Environment } from "globals/enums";
import { CanvasDimensions } from "globals/interfaces";

/**
 * Maximum duration (in seconds) for test evironment video
 * @memberof EnvironmentVariable REACT_APP_TEST_ENV_MAX_DURATION
 * @default 60
 */
export const testEnvMaxDuration: number = +(process.env.REACT_APP_TEST_ENV_MAX_DURATION || 60);
/**
 * Environment for identifying Environment Name.
 * @memberof EnvironmentVariable REACT_APP_ENVIRONMENT
 */
export const environmentName: string = process.env.REACT_APP_ENVIRONMENT || Environment.Development;

/**
 * Environment for base Proview API Urls.
 * @memberof EnvironmentVariable REACT_APP_PROVIEW_API_BASE
 */
export const proctoringApiBaseUrl: string | undefined =
  process.env.REACT_APP_PROCTORING_API_BASE_URL;

/**
 * Environment for base Platform Stream API Urls.
 * @memberof EnvironmentVariable REACT_APP_PLATFORM_STREAM_API_BASE
 */
export const platformStreamApiBaseUrl: string | undefined =
  process.env.REACT_APP_PLATFORM_STREAM_API_BASE;

/**
 * Environment for react app Id
 * @memberof EnvironmentVariable REACT_APP_APP_ID
 */
export const appId: string | undefined = process.env.REACT_APP_APP_ID;

/**
 * Environment for Firebase API Key.
 * @memberof EnvironmentVariable FIREBASE_API_KEY
 */
export const firebaseAPIKey: string | undefined = process.env.REACT_APP_FIREBASE_API_KEY;

/**
 * Environment for Firebase Database URL.
 * @memberof EnvironmentVariable FIREBASE_DATABASE_URL
 */
export const firebaseDatabaseURL: string | undefined = process.env.REACT_APP_FIREBASE_DATABASE_URL;

/**
 * Environment for Firebase Project ID.
 * @memberof EnvironmentVariable FIREBASE_PROJECT_ID
 */
export const firebaseProjectID: string | undefined = process.env.REACT_APP_FIREBASE_PROJECT_ID;

/**
 * Environment for Firebase APP ID.
 * @memberof EnvironmentVariable FIREBASE_APP_ID
 */
export const firebaseAPPID: string | undefined = process.env.REACT_APP_FIREBASE_APP_ID;

/**
 *  Constant for photo id max file size
 *  Default 1024 KB
 */
export const photoIdMaxFileSize = 1024;

export const getStreamAppKey: string | undefined = process.env.REACT_APP_GET_STREAM_KEY;

/**
 * Environment for Twilio audio token refresh time in milli seconds.
 * @memberof EnvironmentVariable TWILIO_AUDIO_TOKEN_REFRESH
 * Default 30000
 */
export const twilioAudioTokenRefresh: number = +(
  process.env.REACT_APP_TWILIO_AUDIO_TOKEN_REFRESH || 30000
);

/**
 * Environment for Mixpanel token.
 * @memberof EnvironmentVariable MIXPANEL_TOKEN
 */
export const mixpanelToken: string | undefined = process.env.REACT_APP_MIXPANEL_TOKEN;

/**
 * Environment for x client version.
 * @memberof EnvironmentVariable X_TALVIEW_CLIENT_VERSION
 */
export const xClientVersion: string = process.env.REACT_APP_X_TALVIEW_CLIENT_VERSION || "0.0.0";

/**
 * Environment for precheck poll interval.
 * @memberof EnvironmentVariable REACT_APP_PRECHECK_POLL_INTERVAL
 */
export const precheckPollingInterval: number = +(
  process.env.REACT_APP_PRECHECK_POLL_INTERVAL || 10000
);

/**
 * Environment for ping interval.
 * @memberof EnvironmentVariable REACT_APP_PING_INTERVAL
 */
export const pingInterval: number = +(process.env.REACT_APP_PING_INTERVAL || 10000);

/**
 *  Constant for canvas Dimensions for camera garb and screen garb
 */
export const canvasDimensions: CanvasDimensions = {
  canvasWidth: 920,
  canvasHeight: 542
};

/**
 *  Constant for canvas Dimensions for photoId and FaceCapture
 */
export const photoIdCanvasDimensions: CanvasDimensions = {
  canvasWidth: 720,
  canvasHeight: 540
};
/**
 *  Constant for chat open audio
 */
export const chatOpenAudio = "https://cdn.freesound.org/previews/320/320655_5260872-lq.mp3";
/**
 *  Constant for solution path of face detection
 */
export const solutionPath = "https://cdn.jsdelivr.net/npm/@mediapipe/face_detection";
/**
 *  Constant for offline threshold
 */
export const candidateOfflineThreshold: number = +(
  process.env.candidate_offline_threshold || 30000
);
/**
 *  Constant for alerts that should notify the candidate
 */

export const alertListToNotifyCandidate = [5, 13, 16, 81, 85, 128, 169, 170, 202];

/**
 * Environment for fresh chat token.
 * @memberof EnvironmentVariable REACT_APP_FRESH_CHAT_TOKEN
 */
export const freshChatToken: string | undefined = process.env.REACT_APP_FRESH_CHAT_TOKEN;

/**
 * Environment for fresh chat host.
 * @memberof EnvironmentVariable REACT_APP_FRESH_CHAT_HOST
 */
export const freshChatHost: string | undefined = process.env.REACT_APP_FRESH_CHAT_HOST;

/**
 * Environment for fresh chat site_id.
 * @memberof EnvironmentVariable REACT_APP_FRESH_CHAT_SITE_ID
 */
export const freshChatSiteId: string | undefined = process.env.REACT_APP_FRESH_CHAT_SITE_ID;

/**
 * Environment for fresh chat script.
 * @memberof EnvironmentVariable REACT_APP_FRESH_CHAT_SCRIPT
 */
export const freshChatScript: string | undefined = process.env.REACT_APP_FRESH_CHAT_SCRIPT;

/**
 *  Constant for DEFAULT_NOTIFICATION_THRESHOLD
 *  Default 2 times
 */
export const defaultNotificationThreshold = 2;

/**
 *  Constant for trace sample rate of sentry
 */
export const tracesSampleRate: number = +(process.env.REACT_APP_TRACES_SAMPLE_RATE || 0.2);

/**
 *  Constant for session replay sample rate of sentry
 */
export const replaysSessionSampleRate: number = +(
  process.env.REACT_APP_REPLAY_SESSION_SAMPLE_RATE || 0.1
);

/**
 *  Constant for error replay sample rate of sentry
 */
export const replaysOnErrorSampleRate: number = +(
  process.env.REACT_APP_REPLAY_ERROR_SAMPLE_RATE || 1.0
);

/**
 *  Constant for sample rate of sentry
 */
export const sampleRate: number = +(process.env.REACT_APP_SAMPLE_RATE || 0.2);

export const secondaryDeviceAppUrl: string | undefined =
  process.env.REACT_APP_SECONDARY_DEVICE_APP_URL;

export const allowedStartBufferTime = 30;

/**
 *  Constant for secure browser launch url
 */
export const sbLaunchUrl = "https://pages.talview.com/securebrowser/index.html";

/**
 *  Constant for default terms and conditions url
 */
export const defaultTermsUrl = "https://www.talview.com/terms";

/**
 *  Constant for default privacy url
 */
export const defaultPrivacyUrl = "https://www.talview.com/privacy";

/**
 *  Constant for default support url
 */
export const defaultSupportUrl = "https://certification.talview.com/support/tickets/new";


export const livekitWSUrl = process.env.REACT_APP_LIVEKIT_WSURL;

export const defaultColor = "#1cb841";
