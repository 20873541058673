import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Header from "../components/Header";
import Base from "../components/layouts/Base";
import FooterLogo from "../components/FooterLogo";
import Button from "../components/Button";
import {
  FullScreenState,
  ScreenShareStatus,
  SecondaryDeviceStatus,
  StorageKeyNames
} from "../globals/enums";
import { useAppDispatch, useAppSelector } from "../store/store";
import { ScreenShareState, updateFullScreenState } from "../services/screenShare";
import { getEndpoint, ProctoringRoute } from "../routes/route";
import { hideProview } from "../helpers/hideProview";
import { AlertLogConfig } from "../services/session-token";
import { AlertName } from "../helpers/alert-type";
import { alertHelper } from "../helpers/alert-helper";

const FullScreen = () => {
  const { screenShareStatus, fullScreenStatus }: ScreenShareState = useAppSelector(
    (state) => state.screenShare
  );
  const alertConfig: AlertLogConfig[] = useAppSelector((state) => state.app.alertConfigs);
  const globalState = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const {
    fireBaseState: { secondary_device_status }
  } = globalState;

  const requestFullScreen = () => {
    try {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      }
      if (fullScreenStatus !== FullScreenState.Started) {
        alertHelper(alertConfig).raiseAlert(AlertName.EnterFullscreen, dispatch);
        dispatch(updateFullScreenState({ fullScreenStatus: FullScreenState.Started }));
      }
    } catch (e) {
      dispatch(updateFullScreenState({ fullScreenStatus: FullScreenState.Error }));
    }
  };

  const handleNavigateToNextScreen = () => {
    if (secondary_device_status === SecondaryDeviceStatus.Disconnected) {
      navigate(getEndpoint(ProctoringRoute.SecondaryDevice));
    } else {
      const currentScreen = sessionStorage.getItem(StorageKeyNames.Screen);
      if (currentScreen === ProctoringRoute.RecorderScreen) {
        hideProview();
      } else {
        navigate(getEndpoint(ProctoringRoute.RecorderScreen));
      }
    }
  };

  useEffect(() => {
    if (screenShareStatus === ScreenShareStatus.PermissionFailed) {
      navigate(getEndpoint(ProctoringRoute.ScreenShare));
    } else if (
      screenShareStatus === ScreenShareStatus.ScreenShareStarted &&
      fullScreenStatus === FullScreenState.Started
    ) {
      handleNavigateToNextScreen();
    }
  }, [screenShareStatus, fullScreenStatus]);

  useEffect(() => {
    const screenHeight = window.outerHeight;

    const body = document.body,
      html = document.documentElement;

    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    if (screenHeight === documentHeight && fullScreenStatus !== FullScreenState.Started) {
      alertHelper(alertConfig).raiseAlert(AlertName.EnterFullscreen, dispatch);
      dispatch(updateFullScreenState({ fullScreenStatus: FullScreenState.Started }));
    }
  }, []);

  return (
    <Base>
      <Header title={""} tabIndex={1} titleClass={"tvp-text-sm tvp-font-semibold clr-dgray tvp-ml-2.5"} />
      <div className="tvp-h-auto tvp-bg-white tvp-flex-auto tvp-flex tvp-flex-col tvp-px-2 tvp-justify-center tvp-content">
        <div className="tvp-w-full tvp-h-auto tvp-bg-white tvp-my-4 tvp-flex tvp-flex-col tvp-text-center">
          <h2 className="tvp-mb-2.5">
            <FormattedMessage id="page.FullScreen.headerText" />
          </h2>
          <p className="tvp-mb-4 tvp-mx-auto tvp-w-[350px]">
            <FormattedMessage id="page.FullScreen.description" />
          </p>
        </div>
        <div className="tvp-parts tvp-justify-center">
          <Button
            tabIndex={2}
            data-testid={"fullscreenbutton"}
            label={<FormattedMessage id="button.EnableAndContinue" />}
            className={
              "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-8 tvp-mt-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-green tvp-bdr-green"
            }
            onClick={requestFullScreen}
          />
        </div>
      </div>
      <FooterLogo />
    </Base>
  );
};

export default FullScreen;
