import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { GlobalAppState } from "../globals/interfaces";
import { useAppSelector } from "../store/store";
import { AppLocale, SubTitleMode } from "../globals/enums";

type VideoProps = {
  onClick?: () => void;
};

const ViewDemo = (props: VideoProps) => {
  const globalState: GlobalAppState = useAppSelector((state) => state.app);

  useEffect(() => {
    const video = document.getElementById("tvp_demo_video_1") as HTMLVideoElement;
    if(globalState.currentLanguage === AppLocale.French) {
      const track = video.addTextTrack("captions", "Captions", "fr");
      track.mode = SubTitleMode.Showing;
      track.addCue(new VTTCue(1, 5, "Étape 01\nVérification des écouteurs\nN'utilisez pas d'écouteurs"));
      track.addCue(new VTTCue(10, 15, "Étape 02\nVérification de la table de travail, de la table, du sol et du plafond :\nEnregistrez l'ensemble de la table de travail, de la table, du sol et du plafond de la pièce dans laquelle vous effectuez le test."));
      track.addCue(new VTTCue(40, 45, "Étape 03\nVérification à 360° de la pièce :\nFaites pivoter la caméra pour obtenir une vue à 360° de la pièce."));
    } else {
      const tracks = video.textTracks;
      for (let i = 0; i < tracks.length; i++) {
        tracks[i].mode = SubTitleMode.Hidden;
      }
    }
  }, [globalState.currentLanguage]);

  return (
    <div className="tvp-flex tvp-flex-auto tvp-flex-col tvp-justify-start tvp-py-2.5 tvp-px-4 tvp-bg-black tvp-DemoWrapper">
      <div className="tvp-flex tvp-flex-row tvp-h-12 tvp-items-center tvp-justify-between tvp-clr-white">
        <h2 className="tvp-ml-2.5">
          <FormattedMessage id="button.Demo" />
        </h2>
        <button
          aria-label="Close demo"
          className={"tvp-cursor-pointer tvp-p-1 tvp-button-no-border"}
          onClick={props.onClick}
        >
          X
        </button>
      </div>

      <div className="tvp-flex tvp-w-full tvp-h-auto tvp-my-4 tvp-flex-grow tvp-flex-col tvp-items-center tvp-justify-between tvp-bg-black">
        <video
          data-testid="video-element"
          id="tvp_demo_video_1"
          src="https://cdn.proview.io/assets/360degree-check-video.mp4"
          controls
          disablePictureInPicture
          controlsList="nodownload"
          autoPlay
        >
        </video>
      </div>
    </div>
  );
};

export default ViewDemo;
