import { useRef, useEffect, useContext } from "react";
import { useAppSelector, useAppDispatch } from "store/store";
import { ScreenShareStatus } from "globals/enums";
import { canvasDimensions } from "config/constant";
import { getScreenGrabBlobPayload } from "helpers/blob-payload";
import { enqueueItem } from "store/screenGrabSlice";
import { MediaContext } from "context/MediaContext";

const useScreenGrab = () => {
  const dispatch = useAppDispatch();
  const storage = useAppSelector((state) => state.app.storage);
  const session = useAppSelector((state) => state.app.session);
  const { screenShareStatus } = useAppSelector((state) => state.screenShare);
  const screenGrab = useAppSelector((state) => state.app.configuration.screen_grab);
  const { displayStream } = useContext(MediaContext);
  const intervalId = useRef<NodeJS.Timer>();

  const createVideoElementForRecordingScreenGrabs = (stream: MediaStream) => {
    const screenVideoElement = document.createElement("video");
    screenVideoElement.autoplay = true;
    screenVideoElement.srcObject = stream;
    return screenVideoElement;
  };

  const createCanvasForScreenShareVideoStream = (screenVideoElement: HTMLVideoElement) => {
    const { canvasWidth, canvasHeight } = canvasDimensions;
    const screenGrabCanvas = document.createElement("canvas");
    const canvasContext = screenGrabCanvas.getContext("2d");
    screenVideoElement.autoplay = true;
    screenGrabCanvas.width = canvasWidth;
    screenGrabCanvas.height = canvasHeight;
    canvasContext?.drawImage(screenVideoElement, 0, 0, canvasWidth, canvasHeight);
    return screenGrabCanvas;
  };

  const startScreenCapture = (stream: MediaStream) => {
    const screenVideoElement = createVideoElementForRecordingScreenGrabs(stream);
    intervalId.current = setInterval(async () => {
      const screenGrabCanvas = createCanvasForScreenShareVideoStream(screenVideoElement);
      screenGrabCanvas.toBlob(async (blob) => {
        dispatch(enqueueItem(getScreenGrabBlobPayload(storage, session, blob)));
      }, "image/png");
    }, 10000);
  };

  const stopScreenShare = async () => {
    clearInterval(intervalId.current);
  };

  useEffect(() => {
    if (!screenGrab) {
      return;
    }
    if (screenShareStatus === ScreenShareStatus.ScreenShareStopped) {
      stopScreenShare();
    }
    if (screenShareStatus === ScreenShareStatus.ScreenShareStarted && displayStream) {
      startScreenCapture(displayStream);
    }
    return () => {
      stopScreenShare();
    };
  }, [screenShareStatus, displayStream]);
};

export default useScreenGrab;
