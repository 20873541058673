import { ErrorTypes, FeatureFlags, SessionScope, StorageKeyNames } from "globals/enums";
import { ProctoringRoute } from "./route";
import mixpanel from "config/mixpanel";
import { detectMob } from "helpers/browser-helper";
import { store } from "store/store";

export const candidateAuthSessionFlow = () => {
  return [
    {
      [ProctoringRoute.Splash]: {
        config: false
      },
      [ProctoringRoute.TermsOfUse]: {
        config: false
      },
      [ProctoringRoute.Instructions]: {
        config: false
      },
      [ProctoringRoute.HardwareTest]: {
        config: false
      },
      [ProctoringRoute.PhotoIDVerification]: {
        config: true,
        name: FeatureFlags.IdVerificationCheck
      },
      [ProctoringRoute.FaceCaptureInstructions]: {
        config: true,
        name: FeatureFlags.FaceCapture
      },
      [ProctoringRoute.FaceCapture]: {
        config: true,
        name: FeatureFlags.FaceCapture
      },
      [ProctoringRoute.HardwareTestInstructions]: {
        config: false
      },
      [ProctoringRoute.AudioDetection]: {
        config: false
      },
      [ProctoringRoute.TestEnvInstructions]: {
        config: true,
        name: FeatureFlags.EnvironmentCheck
      },
      [ProctoringRoute.TestEnvCapture]: {
        config: true,
        name: FeatureFlags.EnvironmentCheck
      }
    }
  ];
};
export const aiAndRnrSessionFlow = () => {
  return [
    {
      [ProctoringRoute.Splash]: {
        config: false
      },
      [ProctoringRoute.TermsOfUse]: {
        config: false
      },
      [ProctoringRoute.Instructions]: {
        config: false
      },
      [ProctoringRoute.HardwareTest]: {
        config: false
      },
      [ProctoringRoute.ScreenShare]: {
        config: false
      },
      [ProctoringRoute.SecondaryDevice]: {
        config: true,
        name: FeatureFlags.SecondaryCamera
      },
      [ProctoringRoute.PhotoIDVerification]: {
        config: true,
        name: FeatureFlags.IdVerificationCheck
      },
      [ProctoringRoute.FaceCaptureInstructions]: {
        config: true,
        name: FeatureFlags.FaceCapture
      },
      [ProctoringRoute.FaceCapture]: {
        config: true,
        name: FeatureFlags.FaceCapture
      },
      [ProctoringRoute.HardwareTestInstructions]: {
        config: false
      },
      [ProctoringRoute.AudioDetection]: {
        config: false
      },
      [ProctoringRoute.TestEnvInstructions]: {
        config: true,
        name: FeatureFlags.EnvironmentCheck
      },
      [ProctoringRoute.TestEnvCapture]: {
        config: true,
        name: FeatureFlags.EnvironmentCheck
      }
    }
  ];
};
export const proctoringWorkFlowForLp = () => {
  return [
    {
      [ProctoringRoute.Splash]: {
        config: false
      },
      [ProctoringRoute.TermsOfUse]: {
        config: false
      },
      [ProctoringRoute.Instructions]: {
        config: false
      },
      [ProctoringRoute.HardwareTest]: {
        config: false
      },
      [ProctoringRoute.ScreenShare]: {
        config: false
      },
      [ProctoringRoute.SecondaryDevice]: {
        config: true,
        name: FeatureFlags.SecondaryCamera
      },
      [ProctoringRoute.PhotoIDVerification]: {
        config: true,
        name: FeatureFlags.IdVerificationCheck
      },
      [ProctoringRoute.FaceCaptureInstructions]: {
        config: true,
        name: FeatureFlags.FaceCapture
      },
      [ProctoringRoute.FaceCapture]: {
        config: true,
        name: FeatureFlags.FaceCapture
      },
      [ProctoringRoute.HardwareTestInstructions]: {
        config: false
      },
      [ProctoringRoute.AudioDetection]: {
        config: false
      },
      [ProctoringRoute.TestEnvInstructions]: {
        config: true,
        name: FeatureFlags.EnvironmentCheck
      },
      [ProctoringRoute.TestEnvCapture]: {
        config: true,
        name: FeatureFlags.EnvironmentCheck
      },
      [ProctoringRoute.VerificationStatus]: {
        config: false
      },
      [ProctoringRoute.FullScreen]: {
        config: true,
        name: FeatureFlags.FullScreenMode
      }
    }
  ];
};

/**
 * Automated workflow will be common from
 * 1) Automated
 * 2) Record and Review
 * 3) Candidate Auth
 */
export const candidateAuthWorkFlow = () => {
  const workFlow = candidateAuthSessionFlow();
  const preFlight: any = {};
  preFlight[ProctoringRoute.PreFlightSuccess] = {
    config: false
  };
  return { ...workFlow[0], ...preFlight };
};
export const automatedWorkflow = () => {
  const workFlow = aiAndRnrSessionFlow();
  const preFlight: any = {};
  preFlight[ProctoringRoute.PreFlightSuccess] = {
    config: false
  };
  return { ...workFlow[0], ...preFlight };
};

export const liveProctoringWorkflow = () => {
  const workFlow = proctoringWorkFlowForLp();
  return workFlow[0];
};

export function nextScreen(
  screen: string,
  configuration: any,
  sessionType: SessionScope,
  isScreenShare?: boolean
): string {
  const currentScreen = sessionStorage.getItem(StorageKeyNames.Screen);
  if (store.getState().app.uiErrorScreen === ErrorTypes.StreamingDisconnection) {
    return;
  }
  if (isScreenShare && currentScreen && currentScreen !== ProctoringRoute.ScreenShare) {
    return currentScreen;
  }
  const workFlow = workFlows[sessionType];
  let screenNames;
  const isMobileSupported = detectMob() && configuration.is_mobile_supported;
  const isScreenRecordingOrGrabDisabled =
    !configuration.screen_recording && !configuration.screen_grab;

  if (isMobileSupported || isScreenRecordingOrGrabDisabled) {
    screenNames = Object.keys(workFlow).filter(
      (screenName) => screenName !== ProctoringRoute.ScreenShare
    );
  } else {
    screenNames = Object.keys(workFlow);
  }
  const getNextScreenName: string = moveToNextScreen(screenNames, screen);
  const nextScreenConfigured = workFlow[getNextScreenName];
  if (!nextScreenConfigured.config) {
    mixpanel.track(getNextScreenName);
    sessionStorage.setItem(StorageKeyNames.Screen, getNextScreenName);
    return getNextScreenName;
  }
  const ifSettingsConfigured = configuration[nextScreenConfigured.name];
  if (ifSettingsConfigured) {
    mixpanel.track(getNextScreenName);
    sessionStorage.setItem(StorageKeyNames.Screen, getNextScreenName);
    return getNextScreenName;
  } else {
    return nextScreen(getNextScreenName, configuration, sessionType);
  }
}

const workFlows = {
  live_proctor: liveProctoringWorkflow(),
  candidate_auth: candidateAuthWorkFlow(),
  record_and_review: automatedWorkflow(),
  ai_proctor: automatedWorkflow()
};

function moveToNextScreen(screenNames: string[], currentScreen: string) {
  const nextScreen =
    window.ProctorClient3.isPhotoIdFailed === true &&
    window.ProctorClient3.isTextEnvFailed === true &&
    currentScreen === ProctoringRoute.FaceCapture
      ? ProctoringRoute.TestEnvInstructions
      : window.ProctorClient3.isPhotoIdFailed === true &&
        window.ProctorClient3.isTextEnvFailed === false &&
        currentScreen === ProctoringRoute.FaceCapture
      ? ProctoringRoute.VerificationStatus
      : screenNames[screenNames.indexOf(currentScreen) + 1];
  return nextScreen;
}
