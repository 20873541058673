import React, { useState } from "react";
import { MediaRecorderState } from "globals/enums";
import { useAppSelector } from "store/store";

const useMediaRecorder = (
  mediaStream: MediaStream | undefined | null,
  handleStop?: (() => void) | null,
  maxDurationInSeconds?: number
): [
  MediaRecorder | undefined,
  Blob | undefined,
  string | undefined,
  string | undefined,
  number | undefined,
  any
] => {
  const [recorder, setRecorder] = React.useState<MediaRecorder>();
  const [data, SetData] = React.useState<Blob>();
  const [startTime, setStartTime] = React.useState<string>();
  const [endTime, setEndTime] = React.useState<string>();
  const [sequenceId, setSequenceId] = React.useState<number>(0);
  const isOnline = useAppSelector((state) => state.app.isOnline);
  const [mediaStreamErrorScreen, setMediaStreamErrorScreen] = useState<any>(null);

  function getMediaMimeType() {
    if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8,opus")) {
      return "video/webm;codecs=vp8,opus";
    }
    return "video/mp4";
  }

  function recordMedia(): void {
    const mediaRecorder = new MediaRecorder(mediaStream!, {
      videoBitsPerSecond: 400000,
      audioBitsPerSecond: 96000,
      mimeType: getMediaMimeType()
    });
    setRecorder(mediaRecorder);
    let timer: any;
    mediaRecorder.ondataavailable = (event) => {
      SetData(event.data);
      if (handleStop) handleStop();
    };
    mediaRecorder.onstart = () => {
      setStartTime(new Date().toISOString());
      if (maxDurationInSeconds) {
        timer = setTimeout(() => {
          setSequenceId((prevSequenceId) => prevSequenceId + 1);
          mediaRecorder?.state !== MediaRecorderState.InActive && mediaRecorder?.stop();
        }, maxDurationInSeconds * 1000);
      }
    };
    mediaRecorder.onerror = (e: any) => {
      console.error(e.error.name);
      setMediaStreamErrorScreen(e.error);
    };
    mediaRecorder.onstop = () => {
      setEndTime(new Date().toISOString());
      if (timer) {
        clearTimeout(timer);
      }
    };
  }

  React.useEffect(() => {
    if (mediaStream) {
      recordMedia();
    }
  }, [mediaStream]);

  React.useEffect(() => {
    if (isOnline) {
      setSequenceId(0);
    }
  }, [isOnline]);

  return [recorder, data, startTime, endTime, sequenceId, mediaStreamErrorScreen];
};

export default useMediaRecorder;
