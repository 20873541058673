import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProctoringRoute, getEndpoint } from "routes/route";
import { SessionScope, StorageKeyNames } from "globals/enums";
import Button from "components/Button";
import FooterLogo from "components/FooterLogo";
import { FormattedMessage } from "react-intl";
import Header from "components/Header";
import { useAppSelector } from "store/store";
import { GlobalAppState } from "globals/interfaces";
import { nextScreen } from "routes/workflow";

import Base from "components/layouts/Base";
import Content from "components/layouts/Content";
import { Session, SessionTokenApiResponse } from "services/session-token";
import FreshChat from "config/freshchat";
import { useInvalidatePreCheckMutation } from "services/precheck";

type Props = {
  //   handleNext: () => void
};
const TermsOfUse: React.FC<Props> = () => {
  const globalState: GlobalAppState = useAppSelector((state) => state.app);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const navigate = useNavigate();

  const handleNext = () => {
    if (isChecked1 && isChecked2) {
      const getNextScreen = nextScreen(
        ProctoringRoute.TermsOfUse,
        globalState.configuration,
        globalState.session.session_type
      );
      navigate(getEndpoint(getNextScreen));
      if (globalState?.session?.session_type === SessionScope.LiveProctor) {
        const chatContainer = document.getElementById("tvp-chat-container") as HTMLElement;
        chatContainer.style.display = "block";
      }
    }
  };

  const [invalidatePreCheck] = useInvalidatePreCheckMutation();

  useEffect(() => {
    if (globalState.session?.session_token) {
      invalidatePreCheck();
    }
    sessionStorage.setItem(StorageKeyNames.Screen, ProctoringRoute.TermsOfUse);
  }, []);

  useEffect(() => {
    initializeFreshChat(globalState?.configuration, globalState?.session);
  }, [globalState.session, globalState.configuration]);

  const initializeFreshChat = (
    configuration: SessionTokenApiResponse["configuration"],
    sessionDetails: Session
  ) => {
    if (
      configuration.chat_support_precheck &&
      sessionDetails.session_type !== SessionScope.CandidateAuth
    ) {
      FreshChat.init(sessionDetails.session_id as number);
    }
  };

  const copyToClipboard = (email) => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        console.log("Copied to clipboard: ", email);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <Base>
      <Header
        title={<FormattedMessage id="page.termsAndConditions.header" />}
        titleClass={"tvp-text-sm tvp-font-semibold tvp-clr-neutral tvp-uppercase tvp-ml-2.5"}
        tabIndex={1}
      />
      <Content>
        <div className="tvp-h-52 tvp-overflow-y-auto tvp-bg-lgray-500 tvp-mx-auto tvp-p-4">
          <span>
            <h6 className="tvp-clr-neutral tvp-my-2">
              <FormattedMessage id="page.termsAndConditions.segment-1.title" />
            </h6>
            <p className="tvp-p-1 tvp-text-xs">
              <FormattedMessage
                id="page.termsAndConditions.segment-1.content"
                values={{
                  a: (...chunks) => (
                    <a
                      className="tvp-m-0"
                      href="https://www.talview.com/terms"
                      tabIndex={-1}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunks}
                    </a>
                  )
                }}
              />
            </p>
            <h6 className="tvp-clr-neutral tvp-my-2">
              <FormattedMessage id="page.termsAndConditions.segment-2.title" />
            </h6>
            <p className="tvp-my-2 tvp-text-xs">
              <FormattedMessage
                id="page.termsAndConditions.segment-2.content-2"
                values={{
                  a: (...chunks) => (
                    <a
                      className="tvp-m-0"
                      href="https://certification.talview.com/support/tickets/new"
                      tabIndex={-1}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunks}
                    </a>
                  ),
                  a2: (...chunks) => (
                    <a
                      className="tvp-m-0"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        copyToClipboard("dpo@talview.com");
                      }}
                      tabIndex={-1}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunks}
                    </a>
                  )
                }}
              />
            </p>
          </span>
        </div>
        <div className="tvp-w-full tvp-flex tvp-conditons tvp-flex-col tvp-my-h-12">
          <div className="tvp-parts">
            <label
              className="tvp-cursor-pointer tvp-flex"
              htmlFor="Consent-for-terms-and-condition"
            >
              <input
                id="Consent-for-terms-and-condition"
                name="Consent for terms and condition"
                type="checkbox"
                checked={isChecked1}
                onChange={() => setIsChecked1(!isChecked1)}
                tabIndex={2}
              />
              <p className="tvp-ml-2">
                <FormattedMessage
                  id="page.termsAndConditions.condition-1"
                  values={{
                    a: (...chunks) => (
                      <a
                        tabIndex={-1}
                        className="tvp-m-0"
                        href="https://www.talview.com/terms"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {chunks}
                      </a>
                    ),
                    a2: (...chunks) => (
                      <a
                        tabIndex={-1}
                        className="tvp-m-0"
                        href="https://www.talview.com/privacy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {chunks}
                      </a>
                    )
                  }}
                />
              </p>
            </label>
          </div>
          <div className="tvp-parts">
            <label className="tvp-cursor-pointer tvp-flex" htmlFor="Consent-for-capture">
              <input
                id="Consent-for-capture"
                name="Consent for capture"
                type="checkbox"
                checked={isChecked2}
                onChange={() => setIsChecked2(!isChecked2)}
                tabIndex={3}
              />
              <p className="tvp-ml-2">
                <FormattedMessage id="page.termsAndConditions.condition-2" />
              </p>
            </label>
          </div>
        </div>
        <div className="tvp-parts tvp-justify-center">
          <Button
            label={<FormattedMessage id="button.proceed" />}
            className={
              isChecked1 && isChecked2
                ? "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-clr-white tvp-mx-auto tvp-bg-green tvp-bdr-green tvp-w-150"
                : "tvp-flex tvp-justify-center tvp-items-center tvp-py-1 tvp-px-5 tvp-rounded tvp-w-auto tvp-border-solid tvp-border-2 tvp-min-w-150 tvp-cursor-pointer tvp-bg-lgray tvp-clr-dgray tvp-mx-auto tvp-bdr-lgray tvp-w-150"
            }
            onClick={handleNext}
            tabIndex={4}
          />
        </div>
      </Content>
      <FooterLogo />
    </Base>
  );
};
export default TermsOfUse;
