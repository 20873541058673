import * as cocoSsd from "@tensorflow-models/coco-ssd";
import * as tf from "@tensorflow/tfjs";
import * as faceapi from "@vladmandic/face-api";
import "@mediapipe/face_detection";
import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-backend-cpu";
import "@tensorflow/tfjs-backend-webgl";
import { createElement, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createRoot } from "react-dom/client";
import { RootState, useAppDispatch } from "store/store";
import { alertHelper } from "helpers/alert-helper";
import { AlertName } from "helpers/alert-type";
import { MediaContext } from "context/MediaContext";
import { SessionScope, StreamingProvider } from "globals/enums";
import { setLinkAndVideo } from "store/app.slice";
import { GlobalAppState } from "globals/interfaces";

const lastObjectAlertHashMap = {
  person: {
    timestamp: null,
    type: AlertName.MultiplePeopleDetected
  },
  mobile: {
    timestamp: null,
    type: AlertName.MobilePhoneDetected
  },
  book: {
    timestamp: null,
    type: AlertName.BookDetected
  }
};

const useFaceObjectDetection = () => {
  const dispatch = useAppDispatch();

  const { videoStream, mediaStreamError } = useContext(MediaContext);

  const {
    showLinkAndVideo,
    configuration,
    isFaceDetectionEnabled,
    isObjectDetectionEnabled,
    precheckSuccess,
    session,
    alertConfigs
  }: GlobalAppState = useSelector((state: RootState) => state.app);

  const [faceModel, setFaceModel] = useState<boolean>(false);
  const [objectModel, setObjectModel] = useState<cocoSsd.ObjectDetection>(null);

  const videoRef = useRef();
  const canvasRef = useRef<HTMLCanvasElement>();
  const intervalRef = useRef<any>();

  const isCandidateAuth = session.session_type === SessionScope.CandidateAuth;
  const previousAlert = useRef<any>();
  const previousFaceDetectedTime = useRef(0);
  const previousPartialFaceDetectedTime = useRef(0);
  const previousFaceNotDetectedTime = useRef(0);
  const previousMultipleFaceDetectedTime = useRef(0);

  const init = async () => {
    if (videoStream && (isFaceDetectionEnabled || isObjectDetectionEnabled)) {
      const tvp_recorder_container = document.getElementById("tvp_recorder_container");
      const videoElement = createElement("video", {
        ref: videoRef,
        id: "recorder_video_custom",
        style: { position: "absolute" },
        width: 720,
        height: 540
      });

      const canvasElement = createElement("canvas", {
        ref: canvasRef,
        style: { position: "absolute" },
        width: 720,
        height: 540
      });

      if (tvp_recorder_container && videoElement && canvasElement) {
        const root = createRoot(tvp_recorder_container);
        root.render([videoElement, canvasElement]);

        if (
          !showLinkAndVideo &&
          configuration.streaming_provider !== StreamingProvider.PlatformStreaming
        ) {
          dispatch(setLinkAndVideo({ showLinkAndVideo: true }));
        }
      }

      await loadModals();
    }
  };

  const loadModals = async () => {
    await tf.setBackend("webgl");
    if (isObjectDetectionEnabled && isFaceDetectionEnabled) {
      if (!faceModel) {
        const MODEL_URL = process.env.REACT_APP_FACE_DETECTION_MODEL_URL;
        Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
          faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
          faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
          faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL)
        ]).then(() => {
          setFaceModel(true);
        });
      }
      if (!objectModel) {
        const objectModal = await cocoSsd.load();
        setObjectModel(objectModal);
      }
    } else if (isObjectDetectionEnabled && !objectModel) {
      const objectModal = await cocoSsd.load();
      setObjectModel(objectModal);
    } else if (isFaceDetectionEnabled && !faceModel) {
      const MODEL_URL = process.env.REACT_APP_FACE_DETECTION_MODEL_URL;
      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL)
      ]).then(() => {
        setFaceModel(true);
      });
    }
  };

  const handleVideoOnPlay = async () => {
    intervalRef.current = setInterval(async () => {
      if (canvasRef && canvasRef.current) {
        const video: any = videoRef.current;
        const canvas = canvasRef.current;
        (canvasRef.current as any).innerHTML = faceapi.createCanvasFromMedia(videoRef.current!);
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // @ts-ignore
        if (video && video?.width > 0 && video?.height > 0 && precheckSuccess) {
          if (isFaceDetectionEnabled && faceModel) {
            const displaySize = {
              width: video?.width,
              height: video?.height
            };
            faceapi.matchDimensions(canvas, displaySize);
            const facePredictions = await faceapi.detectAllFaces(
              videoRef.current!,
              new faceapi.TinyFaceDetectorOptions({
                inputSize: 416,
                scoreThreshold: 0.4
              })
            );
            if (facePredictions.length > 0) {
              facePredictions.forEach((face) => {
                ctx.beginPath();
                ctx.strokeStyle = "green";
                ctx.lineWidth = 4;
                ctx.rect(face.box.x, face.box.y, face.box.width, face.box.height);
                ctx.stroke();
              });
            }
            handleFaceDetections(facePredictions);
          }

          if (isObjectDetectionEnabled && objectModel) {
            const objectPredictions = await objectModel.detect(video);
            const filteredObjectPredictions =
              objectPredictions.length > 0
                ? objectPredictions?.filter(
                    (pred) =>
                      (pred.class === "book" ||
                        pred.class === "cell phone" ||
                        pred.class === "person") &&
                      pred.score > 0.72
                  )
                : [];
            const personDetections = filteredObjectPredictions.filter(
              (pred) => pred.class === "person"
            );
            const otherDetections = filteredObjectPredictions.filter(
              (pred) => pred.class !== "person"
            );
            if (personDetections.length >= 2 || otherDetections.length > 0) {
              filteredObjectPredictions.forEach((object) => {
                ctx.beginPath();
                const [x, y, width, height] = object.bbox;
                ctx.strokeStyle = "red";
                ctx.lineWidth = 4;
                ctx.strokeRect(x, y, width, height);
                ctx.fillStyle = "red";
              });

              checkMobileAlert(filteredObjectPredictions);
              checkBookAlert(filteredObjectPredictions);
              checkPersonAlert(personDetections);
            }
          }
        }
      }
    }, 500);
  };

  let noFaceDetectedCounter = 0,
    multipleFacesDetectedCounter = 0,
    partialFaceDetectedCounter = 0;
  const MAX_NO_FACE_DETECTED_FRAMES = 15;
  const MAX_MULTIPLE_FACE_DETECTED_FRAMES = 10;
  const MAX_PARTIAL_FACE_DETECTED_FRAMES = 15;

  const handleFaceDetections = (facePredictions: any[]) => {
    const faceCount = facePredictions.length;
    let faceAlertType: AlertName;
    if (faceCount === 0) {
      noFaceDetectedCounter++;
      if (noFaceDetectedCounter >= MAX_NO_FACE_DETECTED_FRAMES) {
        faceAlertType = AlertName.FaceNotDetected;
        noFaceDetectedCounter = 0;
        multipleFacesDetectedCounter = 0;
        partialFaceDetectedCounter = 0;
      }
    } else if (faceCount === 1) {
      noFaceDetectedCounter = 0;
      multipleFacesDetectedCounter = 0;
      if (facePredictions[0]._score >= 0.4 && facePredictions[0]._score < 0.7) {
        partialFaceDetectedCounter++;
        if (partialFaceDetectedCounter >= MAX_PARTIAL_FACE_DETECTED_FRAMES) {
          faceAlertType = AlertName.PartialFaceDetected;
          partialFaceDetectedCounter = 0;
        }
      } else {
        faceAlertType = AlertName.FaceDetected;
        partialFaceDetectedCounter = 0;
      }
    } else if (facePredictions.length > 1) {
      multipleFacesDetectedCounter++;
      if (multipleFacesDetectedCounter >= MAX_MULTIPLE_FACE_DETECTED_FRAMES) {
        faceAlertType = AlertName.MultipleFaceDetected;
        multipleFacesDetectedCounter = 0;
        noFaceDetectedCounter = 0;
        partialFaceDetectedCounter = 0;
      }
    }

    if (
      !faceAlertType ||
      (previousAlert.current === AlertName.FaceDetected && previousAlert.current === faceAlertType)
    ) {
      return;
    }

    switch (faceAlertType) {
      case AlertName.FaceDetected:
        if (
          !previousFaceDetectedTime.current ||
          previousFaceNotDetectedTime.current > previousFaceDetectedTime.current
        ) {
          handleFaceAlert(faceAlertType, previousFaceDetectedTime, facePredictions);
        }
        break;
      case AlertName.FaceNotDetected:
        handleFaceAlert(faceAlertType, previousFaceNotDetectedTime, facePredictions);
        break;
      case AlertName.MultipleFaceDetected:
        handleFaceAlert(faceAlertType, previousMultipleFaceDetectedTime, facePredictions);
        break;
      case AlertName.PartialFaceDetected:
        handleFaceAlert(faceAlertType, previousPartialFaceDetectedTime, facePredictions);
        break;
      default:
        return;
    }
  };

  const handleFaceAlert = (
    faceAlertType: AlertName,
    previousTime: React.MutableRefObject<number>,
    facePredictions: any[]
  ) => {
    previousAlert.current = faceAlertType;
    previousTime.current = Date.now();
    alertHelper(alertConfigs).raiseAlert(
      faceAlertType,
      dispatch,
      "",
      JSON.stringify({ model_predictions: facePredictions })
    );
  };

  const checkPersonAlert = (personDetections: cocoSsd.DetectedObject[]) => {
    if (personDetections.length > 1) {
      if (
        !lastObjectAlertHashMap.person ||
        Date.now() - lastObjectAlertHashMap.person.timestamp > 10000
      ) {
        alertHelper(alertConfigs).raiseAlert(
          lastObjectAlertHashMap.person.type,
          dispatch,
          "",
          JSON.stringify({
            detection_confidence: personDetections[0].score,
            model_predictions: personDetections
          })
        );
        lastObjectAlertHashMap.person.timestamp = Date.now();
      }
    }
  };

  const checkMobileAlert = (objectPredictions: cocoSsd.DetectedObject[]) => {
    const mobiles = objectPredictions?.filter((pred) => pred.class === "cell phone");
    if (mobiles.length > 0) {
      if (
        !lastObjectAlertHashMap.mobile ||
        Date.now() - lastObjectAlertHashMap.mobile.timestamp > 10000
      ) {
        alertHelper(alertConfigs).raiseAlert(
          lastObjectAlertHashMap.mobile.type,
          dispatch,
          "",
          JSON.stringify({
            detection_confidence: mobiles[0].score,
            model_predictions: mobiles
          })
        );
        lastObjectAlertHashMap.mobile.timestamp = Date.now();
      }
    }
  };

  const checkBookAlert = (objectPredictions: cocoSsd.DetectedObject[]) => {
    const books = objectPredictions?.filter((pred) => pred.class === "book");
    if (books.length > 0) {
      if (
        !lastObjectAlertHashMap.book ||
        Date.now() - lastObjectAlertHashMap.book.timestamp > 10000
      ) {
        alertHelper(alertConfigs).raiseAlert(
          lastObjectAlertHashMap.book.type,
          dispatch,
          "",
          JSON.stringify({
            detection_confidence: books[0].score,
            model_predictions: books
          })
        );
        lastObjectAlertHashMap.book.timestamp = Date.now();
      }
    }
  };

  useEffect(() => {
    const isModelLoaded = faceModel || objectModel;
    if (isModelLoaded && !mediaStreamError && precheckSuccess) {
      clearInterval(intervalRef.current);
      handleVideoOnPlay();
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [faceModel, objectModel, mediaStreamError, precheckSuccess]);

  useEffect(() => {
    if (
      mediaStreamError &&
      (mediaStreamError.name === "AudioHardwareFailedError" ||
        mediaStreamError.name === "VideoHardwareFailedError")
    ) {
      clearInterval(intervalRef.current);
    }
  }, [mediaStreamError]);

  useEffect(() => {
    const video: any = videoRef.current;
    if (video && videoStream?.id) {
      video.srcObject = videoStream;
      video.setAttribute("playsInline", "");
      video.setAttribute("webkit-playsinline", "true");

      // hotfix for letting the new videostream update
      // after being stopped and started at different pages
      setTimeout(() => {
        video.play();
      }, 150);
    }
  }, [videoRef.current, videoStream]);

  useEffect(() => {
    if (videoStream && !isCandidateAuth) {
      if (configuration.streaming_provider !== StreamingProvider.PlatformStreaming) {
        init();
      } else if (precheckSuccess) {
        init();
      }
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [
    videoStream,
    isFaceDetectionEnabled,
    isObjectDetectionEnabled,
    showLinkAndVideo,
    precheckSuccess
  ]);

  return [isFaceDetectionEnabled, !!isObjectDetectionEnabled];
};

export default useFaceObjectDetection;
