import { rest } from "msw";
import { verification } from "./fixtures/verification_code";
import { getSessionData } from "./fixtures/session_token";
import { getRefreshToken } from "./fixtures/refresh_token";
import { pre_check } from "./fixtures/pre_check";

const PROCTORING_API_URL = "http://localhost:3000";
const SAS_URL = "http://localhost:8080";

const getDateISOString = (minutes: number) => {
  return new Date(new Date().getTime() + minutes * 60000).toISOString();
};

export const handlers = [
  rest.patch(`${PROCTORING_API_URL}/v1/pre-check/invalidate`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.post(`${PROCTORING_API_URL}/v1/token/verification-code`, (req, res, ctx) => {
    return res(ctx.json(JSON.parse(verification)));
  }),
  rest.post(`${PROCTORING_API_URL}/v1/session/token`, (req, res, ctx) => {
    return res(
      ctx.json(
        JSON.parse(getSessionData(getDateISOString(1), getDateISOString(10), getDateISOString(3)))
      )
    );
  }),
  rest.post(`${PROCTORING_API_URL}/v1/alert`, (req, res, ctx) => {
    return res(
      ctx.json(
        JSON.parse(getSessionData(getDateISOString(1), getDateISOString(10), getDateISOString(3)))
      )
    );
  }),
  rest.post(`${PROCTORING_API_URL}/ping`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.post(`${PROCTORING_API_URL}/v1/sas-token/refresh`, (req, res, ctx) => {
    return res(ctx.json(JSON.parse(getRefreshToken(getDateISOString(2)))));
  }),
  rest.post(`${PROCTORING_API_URL}/v1/stream/participant`, (req, res, ctx) => {
    return res(ctx.status(201));
  }),
  rest.get(`${PROCTORING_API_URL}/v1/chat/channel`, (req, res, ctx) => {
    return res(ctx.json(JSON.parse(getRefreshToken(getDateISOString(2)))));
  }),
  rest.put(`${SAS_URL}/*`, (req, res, ctx) => {
    return res(ctx.status(201));
  }),
  rest.get(`${PROCTORING_API_URL}/v1/session/pre-check`, (req, res, ctx) => {
    return res(ctx.json(JSON.parse(pre_check)));
  })
];
